body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.info-box-header {
  font-weight: 700;
  font-size: 18px;
}

.icon-small {
  font-size: 24px;
}

.icon-medium {
  font-size: 32px;
}

.icon-large {
  font-size: 48px
}

.clickable {
  cursor: pointer;
}

.pt-0 {
  padding-top: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.modal-button {
  text-transform: none !important;
  margin-left: 1.5em !important;
  margin-right: 1.5em !important;
  background-color: white !important;
  color: black !important;
  border: 1px solid var(--border) !important;
  box-shadow: none !important;

  &:hover {
    background-color: var(--grey-100) !important;
  }
}

.modal-confirm-button {
  text-transform: none !important;
  margin-left: 1.5em !important;
  margin-right: 1.5em !important;
  background: linear-gradient(96.34deg, #021e40 0%, var(--blue-500) 100%) !important;
  color: white !important;
  
  &:disabled {
    background: #d3d3d3 !important;
    color: black !important;
  }
  
  &:hover {
    background: linear-gradient(96.34deg, #01142b 0%, #005079 100%) !important;
  }
}

.modal-deny-button {
  text-transform: none !important;
  margin-left: 1.5em !important;
  margin-right: 1.5em !important;
  background: linear-gradient(180deg, var(--red-500) 0%, var(--red-700) 100%) !important;
  color: white !important;
  
  &:disabled {
    background: #d3d3d3 !important;
    color: black !important;
  }
  
  &:hover {
    background: linear-gradient(96.34deg, #920438 0%, #4d041f 100%) !important;
  }
}

.page-header {
  font-size: 2.25em !important;
  font-weight: 600 !important;
}

.page-subheader {
  font-size: 1.25em !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Normal"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Normal"]::before
{
  content: "Normal" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Heading"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Heading"]::before
{
  font-family: Raleway, cursive;
  content: "Heading" !important;
  font-size: 16px;
  font-weight: 600;
}

.ql-picker.ql-font .ql-picker-label[data-value="MetaData"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="MetaData"]::before
{
  font-family: "Open Sans", cursive;
  content: "Meta Data" !important;
  font-size: 10px;
}

.ql-font-Heading {
  font-family: Raleway;
  font-size: 16px;
  font-weight: 600;
}

.ql-font-MetaData {
  font-family: "Open Sans";
  font-size: 10px;
}

.ql-container .ql-editor {
  min-height: 20em;
}

.ql-editor:not(.ql-container .ql-editor) {
  height: unset !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ql-editor {
  ol {
    padding: 0 !important;
  }
}

.vertical-center-align {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.no-horiz-padding {
  padding-left: 0  !important;
  padding-right: 0  !important;
}

:root {
  /* Blue */
  --blue-50: #E6F0F6;
  --blue-100: #BBDEFB;
  --blue-300: #5499C3;
  --blue-500: #0067A5;
  --blue-700: #004A70;
  --blue-900: #002B45;

  /* Light Blue */
    --light-blue-50: #E9F7FC;
    --light-blue-700: #177C9E;

  /* Purple */
  --purple-50: #F1F0F6;
  --purple-100: #E1BEE7;
  --purple-300: #A398C5;
  --purple-500: #7666A8;
  --purple-700: #7B1FA2;
  --purple-900: #4A148C;

  /* Red */
  --red-50: #FDE3E9;
  --red-100: #FFCDD2;
  --red-300: #DB7399;
  --red-500: #BF0649;
  --red-700: #7C0732;
  --red-900: #B71C1C;

  --lightRedChipBackground: #DB7399;
  --lightRedChipForeground: #5a0725;

  /* Yellow */
  --yellow-50: #FFFCEB;
  --yellow-100: #FFF9C4;
  --yellow-300: #FFF176;
  --yellow-500: #FBDC3B;
  --yellow-700: #FBC02D;
  --yellow-900: #F57F17;

  /* Orange */
  --orange-50: #FFF3E0;
  --orange-100: #FFE0B2;
  --orange-300: #FFB74D;
  --orange-500: #FF9800;
  --orange-700: #F57C00;
  --orange-900: #E65100;

  /* Orange */
  --orange-300: #FFB74D;

  /* Green */
  --green-50: #F4F9EC;
  --green-100: #C8E6C9;
  --green-300: #81C784;
  --green-500: #8CC63F;
  --green-600: #46981B;
  --green-700: #388E3C;
  --green-900: #1B5E20;

  --lightGreenChipBackground: #81C784;
  --lightGreenChipForeground: #18531b;

  /* Grey */
  --grey-50: #FDFDFD;
  --grey-100: #F3F3F3;
  --grey-300: #CBC8CC;
  --grey-500: #59555B;
  --grey-700: #3B353E;
  --grey-900: #0B0B1C;
  
  --black: #000000;
  --white: #FFFFFF;
  --off-white: #FAFEFF;

  /* Common gradients */
  --dark-blue-gradient: linear-gradient(96.34deg, var(--blue-900) 0%, var(--blue-500) 100%);
  --dark-purple-gradient: linear-gradient(96.34deg, #23112F 0%, #291436 7.81%, #6C3F88 100%);

  /* Semantic */
  --error: var(--red-500);
  --border: var(--grey-300);
}
